import { Link, useLocation } from 'react-router-dom';

const folders = [
  {
    name: '📁 No Model',
    options: [
      {
        id: '61',
        name: 'Income Verified',
        description: '',
        value: 'no_model_income_verified',
      },
      {
        id: '62',
        name: 'Pending Verify Income',
        description: '',
        value: 'no_dq_pending_verify_income',
      },
    ],
  },
  {
    name: '📁 Model Approved',
    options: [
      { id: '02', name: 'New Applications', description: '', value: 'new' },
      {
        id: '03',
        name: 'Income Verified',
        description: '',
        value: 'approved_dq_income_verified',
      },
      {
        id: '05',
        name: 'Pending Verify Income',
        description: '',
        value: 'approved_dq_pending_verify_income',
      },
    ],
  },
  {
    name: '📁 Soft DQ',
    options: [
      {
        id: '02',
        name: 'Income Verified',
        description: '',
        value: 'soft_dq_income_verified',
      },
      {
        id: '03',
        name: 'Pending Verify Income',
        description: '',
        value: 'soft_dq_pending_verify_income',
      },
    ],
  },
  {
    name: '✅ Positive',
    options: [
      { id: '08', name: 'Approved', description: '', value: 'approved' },
    ],
  },
  {
    name: '⚠️ Negative',
    options: [
      { id: '40', name: 'Rejected', description: '', value: 'rejected' },
      { id: '41', name: 'Hard DQ', description: '', value: 'hard_dq' },
      { id: '41', name: 'Black list', description: '', value: 'black_list' },
    ],
  },
];

function LoansFilters({ state }) {
  const { pathname } = useLocation();

  return (
    <div className="loans_filters w-72 h-dashboard overflow-auto sticky top-0 border-r">
      <ul className="pt-6">
        {folders.map(folder => (
          <li className="px-4" key={folder.name}>
            <p>{folder.name}</p>
            <ul className="ml-6 mt-1 mb-2 border-l border-black">
              {folder.options.map((option, key) => (
                <li className="flex" key={key}>
                  <Link
                    to={`/applications/${state}/${option.value}`}
                    className={`px-4 py-2 ${
                      pathname === `/applications/${state}/${option.value}`
                        ? 'text-blue-kiwi font-semibold'
                        : 'text-gray-600 hover:text-gray-900 '
                    }`}
                  >
                    <p>
                      {option.name}{' '}
                      <span className="text-gray-400">
                        {option.description}
                      </span>
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LoansFilters;
