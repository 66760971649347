import { useState, useEffect } from "react";
import Tooltip from "components/UI/Tooltip";
import {
  ClipboardIcon,
  ExclamationCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  LightBulbIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  HashtagIcon,
  NewspaperIcon,
  PhoneIcon,
  StarIcon,
  TagIcon,
  UserCircleIcon,
  XCircleIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/solid";
import statusPlaid from "./UI/StatusPlaid";
import { formatDateWithHour } from "utils/format-dates";
import { isMilitary } from "utils/user-validations";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducer";
import { Link } from "react-router-dom";

const UserHeader = ({ data }) => {
  const dispatch = useDispatch();
  const stateBackPath = useSelector((state) => state.backPath);
  const [pathNames, setPathNames] = useState([]);
  const [toasCopy, setToastCopy] = useState(false);

  const copyText = (event) => {
    const { id } = event.target;

    if (id) {
      navigator.clipboard.writeText(id);
      setToastCopy(true);

      setTimeout(() => {
        setToastCopy(false);
      }, 1500);
    }
  };

  const validateConnect = (item) => {
    let connect = item.plaid_token || item.finicity_token;
    let icon = (
      <CheckCircleIcon
        className="text-green-600 h-5 w-5 mr-1.5"
        aria-hidden="true"
      />
    );

    if (connect) {
      icon = item.plaid_token ? statusPlaid(item) : icon;
    } else {
      icon = (
        <XCircleIcon
          className="text-red-500 h-5 w-5 mr-1.5"
          aria-hidden="true"
        />
      );
    }

    return (
      <>
        {connect ? (
          <div className="mt-2 flex items-center text-sm text-gray-500">
            {icon} &nbsp;
            {item.plaid_token ? "Plaid" : "Finicity"}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  useEffect(() => {
    if (stateBackPath)
      setPathNames(stateBackPath?.path?.split("/").filter((x) => x) ?? []);
  }, [stateBackPath]);

  useEffect(() => {
    return () => {
      setPathNames([]);
      dispatch(
        actions.setBackPath({
          path: null,
        })
      );
    };
  }, []);

  return (
    <header className="pt-8 pb-6 px-6 lg:px-8 border-b">
      <div className="max-w-7xl mx-auto xl:flex xl:items-center xl:justify-between">
        {data && (
          <div className="flex-1 min-w-0">
            {pathNames.length > 0 && (
              <ul className="flex gap-x-2.5 pb-5">
                {pathNames.map((pathname, index) => {
                  const routeTo = `/${pathNames.slice(0, index + 1).join("/")}`;
                  return (
                    <li key={pathname} className="flex items-center gap-2.5 ">
                      <Link
                        to={routeTo}
                        className="text-gray-600 hover:underline capitalize text-sm hover:text-blue-kiwi transition-all duration-300 ease-in-out"
                      >
                        {pathname.replace(/_/g, " ")}
                      </Link>
                      {index < pathNames.length - 1 && (
                        <ChevronRightIcon className="h-4 text-gray-600" />
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
            <div className="flex items-center justify-between gap-5">
              <h1 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate">
                {data.first_name} {data.second_name}{" "}
                {data.first_surname ?? data.last_name ?? ""}{" "}
                {data.second_surname}
              </h1>
              <div className="bg-blue-kiwi/20 px-8 py-2 rounded-md">
                <p className="capitalize font-semibold">
                  {data.state === "Puerto Rico"
                    ? "🇵🇷 Puerto Rico"
                    : `🇺🇸 ${data.state}`}
                </p>
              </div>
            </div>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8 mb-2">
              {validateConnect(data)}
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CheckBadgeIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {data.credit_scorings.length
                  ? data.credit_scorings[0].score
                  : 0}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <PhoneIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <PhoneIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {data.phone_number}

                <Tooltip tooltip="Copy Phone Number">
                  <ClipboardIcon
                    id={data.phone_number}
                    onClick={copyText}
                    className="text-blue-kiwi ml-1 h-4 w-4 cursor-pointer hover:text-blue-dark-kiwi"
                  />
                </Tooltip>
              </div>

              <div className="mt-2 flex items-center text-sm text-gray-500 whitespace-nowrap">
                <ClockIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {formatDateWithHour(data.created_at)}
              </div>

              <div className="mt-2 flex items-center text-sm text-gray-500 whitespace-nowrap">
                <HashtagIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {data.firebase_id}

                <Tooltip tooltip="Copy id Firebase">
                  <ClipboardIcon
                    id={data.firebase_id}
                    onClick={copyText}
                    className="text-blue-kiwi ml-1 h-4 w-4 cursor-pointer hover:text-blue-dark-kiwi"
                  />
                </Tooltip>
              </div>
              {data.loanpro_id && (
                <div className="mt-2 flex items-center text-sm text-gray-500 whitespace-nowrap">
                  <NewspaperIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {data.loanpro_id}

                  <Tooltip tooltip="Copy id loan">
                    <ClipboardIcon
                      id={data.loanpro_id}
                      onClick={copyText}
                      className="text-blue-kiwi ml-1 h-4 w-4 cursor-pointer hover:text-blue-dark-kiwi"
                    />
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="flex flex-wrap items-center">
              {isMilitary(data) && (
                <div className="mt-2 flex items-center text-sm py-1 px-2 rounded mr-2 text-red-700 bg-red-300">
                  <UserCircleIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Active Militar
                </div>
              )}
              <div
                className={`mt-2 flex items-center text-sm py-1 px-2 rounded text-green-700 bg-green-300 mr-2`}
              >
                <LightBulbIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                {data?.application?.dq_decision_value || "No Model"}{" "}
                <span className="ml-2 bg-green-400 rounded px-2 text-white uppercase">
                  {data?.application?.dq_decision_rule || ""}
                </span>
              </div>
              <div
                className={`mt-2 flex items-center text-sm py-1 px-2 rounded text-yellow-700 bg-yellow-300 mr-2`}
              >
                <StarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Score{" "}
                <span className="ml-2 bg-yellow-400 rounded px-2 text-white uppercase">
                  {data?.application?.score_kiwi || "XX"}
                </span>
              </div>
              {data?.revalidation_reasons?.map((reason, index) => (
                <div
                  key={index}
                  className="mt-2 flex items-center text-sm py-1 px-2 rounded text-yellow-700 bg-yellow-300 mr-2"
                >
                  <StarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  {reason}
                  <span className="ml-2 bg-yellow-400 rounded px-2 text-white uppercase">
                    {data?.revalidation_stars || "XX"}
                  </span>
                </div>
              ))}

              {data.fraud_model_rate && (
                <div
                  className={`mt-2 flex items-center text-sm py-1 px-2 rounded text-yellow-700 bg-yellow-300 mr-2 ${
                    !data?.application?.score_kiwi && "blur-sm opacity-50"
                  }`}
                >
                  <StarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  First Payment Default Model{" "}
                  <span className="ml-2 bg-yellow-400 rounded px-2 text-white">
                    {data.fraud_model_rate}
                  </span>
                </div>
              )}
              {data.credit_risk_model_rate && (
                <div
                  className={`mt-2 flex items-center text-sm py-1 px-2 rounded text-yellow-700 bg-yellow-300 mr-2 ${
                    !data?.application?.score_kiwi && "blur-sm opacity-50"
                  }`}
                >
                  <StarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Credit Risk Model{" "}
                  <span className="ml-2 bg-yellow-400 rounded px-2 text-white">
                    {data.credit_risk_model_rate}
                  </span>
                </div>
              )}
              {data?.application?.last_status?.value &&
                data?.application?.last_status?.value !== "black_list" && (
                  <div
                    className={`mt-2 flex items-center text-sm py-1 px-2 rounded text-blue-700 bg-blue-300 mr-2`}
                  >
                    <TagIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    {data?.application?.last_status?.value}
                    <span className="ml-2 bg-blue-400 rounded px-2 text-white">
                      {data?.application?.last_status?.reason}
                    </span>
                  </div>
                )}
              {data?.black_list && (
                <div
                  className={`mt-2 flex items-center text-sm py-1 px-2 rounded bg-black mr-2 text-white`}
                >
                  <ExclamationTriangleIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Black List
                </div>
              )}
              {data?.surveys[0]?.is_overdispersed && (
                <div
                  className={`mt-2 flex items-center text-sm py-1 px-2 rounded mr-2 text-yellow-300 bg-yellow-700`}
                >
                  <ExclamationCircleIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Overdispersed
                </div>
              )}

              {data?.ofac_check && (
                <div
                  className={`mt-2 flex items-center text-sm py-1 px-2 rounded bg-red-800 mr-2 text-white cursor-pointer`}
                >
                  <ShieldExclamationIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Ofac Match
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {toasCopy ? (
        <div
          id={Math.random()}
          aria-live="assertive"
          className="fixed inset-0 z-50 flex items-start mt-10 px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div
            id={Math.random()}
            className="w-full flex flex-col items-center space-y-4 sm:items-center"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="relative flex flex-col items-center group">
                <div className="absolute bottom-0 flex flex-col items-center mb-6 group-hover:flex">
                  <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 shadow-lg rounded-md">
                    Id copied successfully
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </header>
  );
};

export default UserHeader;
