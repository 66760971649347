import Cookies from 'js-cookie';

export default function NewCMS() {
    const {REACT_APP_NEW_CMS, REACT_APP_NEW_CMS_COOKIE} = process.env;

    const setNewToken = () => {
        const token = Cookies.get('kiwi_auth_token');
        Cookies.set('auth_token', token, {
            domain: REACT_APP_NEW_CMS_COOKIE,
        });
    };

    return (
        <div className="flex items-center justify-center py-4  bg-dark-kiwi-v2">
            <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
                <p className="text-sm leading-6 text-white">
                    <strong className="font-semibold">New CMS now</strong>
                    <svg
                        viewBox="0 0 2 2"
                        aria-hidden="true"
                        className="mx-2 inline h-0.5 w-0.5 fill-current">
                        <circle r={1} cx={1} cy={1} />
                    </svg>
                    The beta version of the new CMS is now available.
                </p>
                <a
                    href={REACT_APP_NEW_CMS ?? '#'}
                    onClick={setNewToken}
                    target="_blank"
                    className="rounded-full bg-white px-5 py-1 text-sm font-semibold text-dark-kiwi-v2 shadow-sm cursor-pointer">
                    Explore now <span aria-hidden="true">&rarr;</span>
                </a>
            </div>
        </div>
    );
}
